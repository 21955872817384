import { Component, Vue, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { RegisterRandomRequest } from "@/api/authcode/request";
import AuthcodeRegisterRandom from "@/store/authcode/register-random";
import Flash, { ErrorAlert } from "@/store/common/flash";
import WindowSizeChecker from "@/utils/window-size-checker";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, VueQrcode }
})

export default class Qrcode extends Vue {
  @Prop({ type: String, required: true })
  id!: string;
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "スタンプ";
  headingSub = "Stamps";

  // setInterval()を呼び出して作成したタイマーを識別するID
  intervalId: number | null = null;
  // カウントダウンタイマーの秒数の上限値
  private readonly maxCountdownSeconds: number = 3600;
  // 認証コードの更新ON
  private readonly isAuthCodeRefreshActive: number = 1;
  // カウントダウンタイマーの残り時間
  remainingTime: number = 0;

  // パンくずリスト(スタンプごとの店舗IDを用いるため算出プロパティに変更)
  get breadCrumbs() {
    return [
      { text: "アピール", disabled: true },
      { text: "スタンプ", disabled: true },
      { text: "店舗一覧", disabled: false, to: { name: "stamps" } },
      { text: "スタンプ一覧", disabled: true },
      { text: "条件設定", disabled: true },
      { text: "QR表示", disabled: true }
    ];
  }

  // ------------
  // 変動値
  // ------------
  authCode: string = "";
  stampAuthId: string = "";
  authCodeRefleshFlg: number | null = null;
  authCodeRefleshIntervalSeconds: number | null = null;
  authCodeWriteFlg: number = 0;

  /**
   * クエリパラメータから店舗IDを返却する（必須）
   */
  get shopId() {
    const shopIdfromQueryParam = this.$route.query.shopId;
    if (shopIdfromQueryParam) {
      return shopIdfromQueryParam;
    } else {
      return "";
    }
  }
  
  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    const shopNamefromQueryParam = this.$route.query.shopName;
    if (shopNamefromQueryParam) {
      return shopNamefromQueryParam;
    } else {
      return "";
    }
  }

  // QRコードの幅
  qrcodeWidth: number = this.isPhone ? 150 : 300;

  // QRコードの設定
  qrOptions = {
    errorCorrectionLevel: "H",
    margin: 0,
    width: this.qrcodeWidth
  };

  /**
   * 幅がSPか否か
   */
  get isPhone() {
    return WindowSizeChecker.isPhone(this);
  }

  /**
   * カウントダウンタイマーの残り時間をMM:SS形式に変換して返却する
   * @returns - カウントダウンタイマーのMM:SS形式の残り時間
   */
  get formattedCountDownTime(): string {
    const minutes = Math.floor(this.remainingTime / 60);
    const seconds = this.remainingTime % 60;
    return `${this.padTime(minutes)}:${this.padTime(seconds)}`;
  }

  /**
   * 左0詰め2桁の数字を作る
   * @param value - 左0詰め2桁にする数字
   * @returns - 左0詰め2桁になった数字
   */
  padTime(value: number): string {
    return String(value).padStart(2, '0');
  }

  async created() {
    await this.registerRandom();
    this.setProperty();
    if (this.authCodeRefleshFlg === this.isAuthCodeRefreshActive && this.authCodeRefleshIntervalSeconds != null) {
      this.setCountdown(this.authCodeRefleshIntervalSeconds);
    }
  }
  
  async beforeDestroy() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }
    await AuthcodeRegisterRandom.clearResponse();
  }

  /**
   * 認証コードリフレッシュAPIを実行する
   */
  async registerRandom() {
    await AuthcodeRegisterRandom.registerRandom(this.createRegisterRandomRequest());
    if (!AuthcodeRegisterRandom.isSuccess) {
      await Flash.setErrorNow({
        message: AuthcodeRegisterRandom.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  /**
   * 認証コードリフレッシュAPIのレスポンスをプロパティにセットする
   */
  setProperty() {
    this.$set(this, 'authCode', AuthcodeRegisterRandom.getStampAuthCode);
    this.$set(this, 'stampAuthId', AuthcodeRegisterRandom.getStampAuthId);
    this.$set(this, 'authCodeRefleshFlg', AuthcodeRegisterRandom.getAuthCodeRefleshFlg);
    this.$set(this, 'authCodeRefleshIntervalSeconds', AuthcodeRegisterRandom.getAuthCodeRefleshIntervalSeconds);
    this.$set(this, 'authCodeWriteFlg', AuthcodeRegisterRandom.getAuthCodeWriteFlg);
  }

  /**
   * カウントダウンタイマーの残り時間を設定してカウントダウンを開始する
   * @param authCodeRefleshIntervalSeconds - 認証コード更新間隔の秒数
   */
  setCountdown(authCodeRefleshIntervalSeconds: number): void {
    this.remainingTime = authCodeRefleshIntervalSeconds > this.maxCountdownSeconds ? this.maxCountdownSeconds : authCodeRefleshIntervalSeconds;
    this.startCountdown();
  }

  /**
   * カウントダウンを開始し、時間経過したら画面をリロードする
   */
  startCountdown(): void {
    this.intervalId = window.setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime -= 1;
      } else {
        if (this.intervalId !== null) {
          clearInterval(this.intervalId);
        }
        window.location.reload();
      }
    }, 1000)
  }

  /**
   * 認証コードリフレッシュAPIのリクエストを作成する
   */
  private createRegisterRandomRequest(): RegisterRandomRequest {
    return {
      stampCardId: Number(this.id),
      shopId: Number(this.shopId)
    } as RegisterRandomRequest;
  }

  /**
   * 認証コードボタンがクリックされた際のコールバック
   */
  authCodeWriteClickCallback() {
    if (this.authCodeRefleshFlg === this.isAuthCodeRefreshActive) {
      this.registerRandom(); 
    }
    const query = {
      authType: "0",
      stampAuthId: this.stampAuthId,
      shopName: this.shopName,
      fromStampQrcode: "1"
    };
    this.$router
      .push({
        name: "auth-code-edit",
        params: { id: String(this.shopId) },
        query: query
      })
      .then();
  }

  /**
   * 戻るボタンがクリックされた際のコールバック
   */
  goBack() {
    if (this.authCodeRefleshFlg === this.isAuthCodeRefreshActive) {
      this.registerRandom(); 
    }
    this.$router.back();
  }
}