import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { RegisterRandomRequest } from "@/api/authcode/request";
import { RegisterRandomResponse } from "@/api/authcode/response";
import * as AuthcodeAPI from "@/api/authcode";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "authcode/register-random";

/**
 * 【Admin】スタンプ認証コードリフレッシュAPI（/register-authcode-random）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class RegisterRandom extends VuexModule {
  // state
  registerRandomResponse: RegisterRandomResponse = {} as RegisterRandomResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.registerRandomResponse);
  }

  get getMessage() {
    return this.registerRandomResponse.message;
  }

  get getStampAuthCode() {
    return this.registerRandomResponse.results.authCode;
  }

  get getStampAuthId() {
    return this.registerRandomResponse.results.stampAuthId;
  }
  
  get getAuthCodeRefleshFlg() {
    return this.registerRandomResponse.results.authCodeRefleshFlg;
  }

  get getAuthCodeRefleshIntervalSeconds() {
    return this.registerRandomResponse.results.authCodeRefleshIntervalSeconds;
  }

  get getAuthCodeWriteFlg() {
    return this.registerRandomResponse.results.authCodeWriteFlg;
  }

  get getSelectedShopName() {
    return this.registerRandomResponse.results.selectedShopName;
  }

  // MutationActions
  @MutationAction
  async registerRandom(registerRandomRequest: RegisterRandomRequest) {
    const registerRandomResponse = await AuthcodeAPI.registerRandom(registerRandomRequest);
    return {
      registerRandomResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      registerRandomResponse: {} as RegisterRandomResponse
    };
  }
}

export default getModule(RegisterRandom);
